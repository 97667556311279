import {
  faCheckCircle,
  faClock,
  faComments,
  faExclamationCircle,
  faMap,
  faPhone,
} from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React, { useState } from "react"
import {
  Button,
  Card,
  Col,
  Container,
  Form,
  Jumbotron,
  Modal,
  Row,
  Spinner,
} from "react-bootstrap"
import Layout from "../components/layout"

export default function Contact() {
  const [fullName, setFullName] = useState("")
  const [email, setEmail] = useState("")
  const [phoneNumber, setPhoneNumber] = useState("")
  const [subject, setSubject] = useState("")
  const [message, setMessage] = useState("")
  const [modalIsVisible, setModalIsVisible] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [submissionWasSuccessful, setSubmissionWasSuccessful] = useState(null)

  const emailIsValid = /^\S+@\S+.\S+$/.test(email)
  const phoneNumberIsValid = /^[0-9]{10}$/.test(phoneNumber)
  return (
    <Layout>
      <Jumbotron className="bg-secondary mb-0 p-4 text-center" fluid>
        <FontAwesomeIcon
          className="text-light-green"
          icon={faComments}
          size="6x"
        />
        <h1 className="fw-bold h2 mt-4">Drop Us a Line</h1>
        <p className="mt-4 light-green">
          Get in touch with us by completing the contact form below.
        </p>
      </Jumbotron>
      <Container className="p-4 text-center">
        <Row className="justify-content-center">
          <Col className="p-md-3" md={5}>
            <Card className="bg-light-green h-100" text="dark">
              <Card.Body>
                <FontAwesomeIcon
                  className="text-secondary"
                  icon={faClock}
                  size="6x"
                />
                <Card.Title className="mt-3">Office Hours</Card.Title>
                <Card.Text>
                  Mon&ndash;Fri: 9 AM&ndash;5 PM
                  <br />
                  Sat&ndash;Sun: Closed
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col className="mt-3 mt-md-0 p-md-3" md={5}>
            <a className="text-white" href="tel:205-366-1370">
              <Card bg="secondary" className="h-100">
                <Card.Body>
                  <FontAwesomeIcon
                    className="text-light-green"
                    icon={faPhone}
                    size="6x"
                  />
                  <Card.Title className="mt-3">Telephone</Card.Title>
                  <Card.Text>(205) 366-1370</Card.Text>
                </Card.Body>
              </Card>{" "}
            </a>
          </Col>
          <Col className="mt-3 mt-md-0 p-md-3" md={5}>
            <Card text="dark" className="h-100">
              <Card.Body>
                <FontAwesomeIcon icon={faMap} size="6x" />
                <Card.Title className="mt-3">Address</Card.Title>
                <Card.Text>
                  2304 University Blvd
                  <br />
                  Unit 100
                  <br />
                  Tuscaloosa, AL 35401
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col className="mt-3 mt-md-0 p-md-3" md={5}>
            <Card bg="secondary" className="h-100">
              <Card.Body>
                <Card.Text>
                  <iframe
                    className="w-100"
                    height="220"
                    loading="lazy"
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3338.137940093231!2d-87.56951698476638!3d33.21047976906403!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x888602e8f47ced21%3A0xfd050a20d411d190!2sMark%20Sterling%20Gober%2C%20LLC%2C%20Attorney%20at%20Law!5e0!3m2!1sen!2sus!4v1641506667988!5m2!1sen!2sus"
                    title="Mark Gober LLC Map"
                  />
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
      <Container className="bg-secondary p-4">
        <Form
          onSubmit={event => {
            event.preventDefault()

            setModalIsVisible(true)
            setIsLoading(true)

            console.log(
              JSON.stringify({
                fullName,
                email,
                phoneNumber,
                subject,
                message,
              })
            )

            fetch(`${process.env.API_URL}/contact`, {
              method: "POST",
              headers: { "Content-Type": "application/json" },
              body: JSON.stringify({
                fullName,
                email,
                phoneNumber,
                subject,
                message,
              }),
            }).then(res => {
              setIsLoading(false)
              setSubmissionWasSuccessful(res.ok)
            })
          }}
        >
          <Row>
            <Col md={6}>
              <Form.Group>
                <Form.Label>Full Name</Form.Label>
                <Form.Control
                  className="bg-dark text-light"
                  isValid={fullName}
                  onChange={event => setFullName(event.target.value)}
                  placeholder="John Doe"
                  type="text"
                  value={fullName}
                />
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group>
                <Form.Label>Email</Form.Label>
                <Form.Control
                  className="bg-dark text-light"
                  isInvalid={email && !emailIsValid}
                  isValid={emailIsValid}
                  onChange={event => setEmail(event.target.value)}
                  type="email"
                  placeholder="johndoe@example.com"
                  value={email}
                />
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group>
                <Form.Label>Phone Number</Form.Label>
                <Form.Control
                  className="bg-dark text-light"
                  isInvalid={phoneNumber && !phoneNumberIsValid}
                  isValid={phoneNumberIsValid}
                  onChange={event => setPhoneNumber(event.target.value)}
                  type="tel"
                  placeholder="0123456789"
                  value={phoneNumber}
                />
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group>
                <Form.Label>Subject</Form.Label>
                <Form.Control
                  className="bg-dark text-light"
                  isValid={subject}
                  onChange={event => setSubject(event.target.value)}
                  type="text"
                  placeholder="Title of Your Message"
                  value={subject}
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group>
                <Form.Label>Message</Form.Label>
                <Form.Control
                  as="textarea"
                  className="bg-dark text-light"
                  isValid={message}
                  onChange={event => setMessage(event.target.value)}
                  placeholder="Write your message here."
                  rows={3}
                  value={message}
                />
              </Form.Group>
            </Col>
          </Row>
          <Button
            className="bg-light-green text-dark"
            disabled={
              !(
                fullName &&
                emailIsValid &&
                phoneNumberIsValid &&
                subject &&
                message
              )
            }
            type="submit"
          >
            Submit
          </Button>
          <Modal
            centered
            className="text-dark"
            onHide={() => setModalIsVisible(false)}
            show={modalIsVisible}
          >
            <Modal.Header closeButton>
              <Modal.Title>Form Submission</Modal.Title>
            </Modal.Header>
            <Modal.Body className="text-center">
              {isLoading ? (
                <Spinner animation="border" className="light-green" />
              ) : submissionWasSuccessful ? (
                <div className="text-success">
                  <FontAwesomeIcon icon={faCheckCircle} size="4x" />
                  <p className="mt-3">
                    Thank you for contacting us! We will be in touch soon.
                  </p>
                </div>
              ) : (
                <div className="text-danger">
                  <FontAwesomeIcon icon={faExclamationCircle} size="4x" />
                  <p className="mt-3">
                    Uh-oh! Looks like something went wrong. Please try again in
                    a moment.
                  </p>
                </div>
              )}
            </Modal.Body>
          </Modal>
        </Form>
      </Container>
    </Layout>
  )
}
